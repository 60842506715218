import React, { useCallback, useMemo, useRef, useContext, type FC } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import {
	FlyoutMenuItem,
	FlyoutMenuItemContent,
	FlyoutMenuItemTrigger,
} from '@atlassian/navigation-system/side-nav/flyout-menu-item';

import { LoadableLazy } from '@confluence/loadable';
import { preloadSpacesList } from '@confluence/space-utils/entry-points/preloadSpacesList';
import { SSRMouseEventWrapper, SSR_NAV_SPACES_BUTTON_METRIC } from '@confluence/browser-metrics';
import { usePageSpaceKey } from '@confluence/page-context';
import { useSessionData, useBooleanFeatureFlag } from '@confluence/session-data';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { useRouteDataRef } from '@confluence/route-manager';
import {
	APP_NAV_SPACES_DROPDOWN_EXPERIENCE,
	ExperienceTrackerContext,
	ExperienceTimeout,
} from '@confluence/experience-tracker';

import { i18n } from '../globalNavigationTranslations';
import { GlobeIconComponent } from '../GlobalNavigationIcons';
import { ContentMenuLoadingState } from '../../ContentMenu/ContentMenuStates';
import { useFlyoutStore } from '../FlyoutStore';

import type { GlobalItemProps } from './globalItemProps';
import { useGlobalItemVisibility } from './useGlobalItemVisibility';

const FLYOUT_LABEL = 'SPACES';
export const SpacesDropdownLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-global-spaces-dropdown" */ '@confluence/spaces-dropdown'
			)
		).SpacesDropdownMenu,
	loading: () => <ContentMenuLoadingState />,
});

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ '../analyticsCallbacks'))
			.fireSpacesClickedAnalytics,
);

export const SpacesItem: FC<GlobalItemProps> = ({ isHidden, peekingId, setPeekingId }) => {
	const [flyoutState, flyoutActions] = useFlyoutStore();
	const isFlyoutOpen = useMemo(() => flyoutState.openFlyout === FLYOUT_LABEL, [flyoutState]);
	const openFlyout = useCallback(() => flyoutActions.openFlyout(FLYOUT_LABEL), [flyoutActions]);
	const closeFlyout = useCallback(() => flyoutActions.closeFlyout(FLYOUT_LABEL), [flyoutActions]);

	const routeDataRef = useRouteDataRef();
	const [spaceKey] = usePageSpaceKey();
	const selectedSpaceKey: string | undefined = spaceKey;
	const { isLicensed } = useSessionData();
	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');

	const isMounted = useRef(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireClickAnalytics = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);
	const experienceTracker = useContext(ExperienceTrackerContext);

	const abortExperienceTracker = useCallback(() => {
		experienceTracker.abort({
			name: APP_NAV_SPACES_DROPDOWN_EXPERIENCE,
			reason: 'Primary dropdown menu: closed by user',
			attributes: {
				navVersion: '4',
				dropdownType: 'spaces',
			},
		});
	}, [experienceTracker]);

	const startExperienceTracker = useCallback(() => {
		experienceTracker.start({
			name: APP_NAV_SPACES_DROPDOWN_EXPERIENCE,
			timeout: ExperienceTimeout.NAVIGATION_LOAD,
			attributes: {
				navVersion: '4',
				dropdownType: 'spaces',
			},
		});
	}, [experienceTracker]);

	const onOpenChange = useCallback(
		(newIsOpen: boolean) => {
			if (!isMounted.current) {
				isMounted.current = true;
				return;
			}
			if (!newIsOpen) {
				abortExperienceTracker();
				void fireClickAnalytics(false);
				isFlyoutOpen && closeFlyout();
			} else {
				openFlyout();
			}
		},
		[abortExperienceTracker, fireClickAnalytics, isFlyoutOpen, openFlyout, closeFlyout],
	);

	const onClick = useCallback(() => {
		if (!isFlyoutOpen) {
			openFlyout();
			startExperienceTracker();
			void fireClickAnalytics(true);
		} else {
			closeFlyout();
		}
	}, [fireClickAnalytics, isFlyoutOpen, startExperienceTracker, openFlyout, closeFlyout]);

	const onMouseEnter = useCallback(() => {
		if (!isFlyoutOpen) {
			void SpacesDropdownLoader.preload();
			if (selectedSpaceKey) {
				void preloadSpacesList({
					isLicensed,
					isSpaceAliasFFEnabled,
					selectedSpaceKey,
				});
			}
		}
	}, [isFlyoutOpen, isLicensed, isSpaceAliasFFEnabled, selectedSpaceKey]);

	const isSelected = false; //toDo: this will be the all Spaces URL once flyouts support selected state
	const shouldHide = useGlobalItemVisibility(
		'spaces',
		isSelected,
		isHidden,
		peekingId,
		setPeekingId,
	);

	if (shouldHide) {
		return null;
	}

	return (
		<SSRMouseEventWrapper metricName={SSR_NAV_SPACES_BUTTON_METRIC}>
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
			<div onMouseEnter={onMouseEnter}>
				<FlyoutMenuItem onOpenChange={onOpenChange} isOpen={isFlyoutOpen}>
					<FlyoutMenuItemTrigger iconBefore={GlobeIconComponent} onClick={onClick}>
						<FormattedMessage {...i18n.spaces} />
					</FlyoutMenuItemTrigger>
					<FlyoutMenuItemContent onClose={() => isFlyoutOpen && closeFlyout()}>
						<Box xcss={fixFocus}>
							<SpacesDropdownLoader isNav4Enabled />
						</Box>
					</FlyoutMenuItemContent>
				</FlyoutMenuItem>
			</div>
		</SSRMouseEventWrapper>
	);
};

const fixFocus = xcss({
	//@ts-expect-error styling is applying but property does not exist for SafeCSSObject
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	a: {
		':focus-visible': {
			outlineOffset: `${token('space.negative.025', '-2px')}`,
		},
	},
});
